.ant-modal.PrivacyPolicyModal {
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
}

.ant-modal.PrivacyPolicyModal.AirModal .ant-modal-content {
  /*width: 720px;*/
}

.ant-modal.PrivacyPolicyModal.AirModal .top {
  display: none;
}

.ant-modal.PrivacyPolicyModal .title {
  height: 32px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.ant-modal.PrivacyPolicyModal .content {
  font-size: 10px;
  padding: 10px;
}

.DesktopReg {
  margin-left: auto;
  margin-right: auto;
}
/*
  @media (max-width: 1272px) {
    .DesktopReg {
    margin-bottom: 56px;
  }
  }
  */

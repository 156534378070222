
.MyPageReg {}

.MyPageReg .ant-modal-confirm .ant-modal-body {
    background: white;
}

.SuccessModalContainer {
  padding: 0 15px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-modal.MyPageRegModal .content {
    font-size: 14px;
  }

  .ant-modal.MyPageRegModal.AirModal .ant-modal-content {
    /*width: 720px;*/
  }

  .ant-modal.MyPageRegModal.AirModal .top {
    display: none;
  }

  .ant-modal.MyPageRegModal .title {
    height: 32px;
    color: var(--black-color);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
  }

.MoVeInExistingVehicleNewIdAdesioneModal .content {
  font-size: 14px;
}

.MoVeInExistingVehicleNewIdAdesioneModal .top {
  display: none;
}

.MoVeInExistingVehicleNewIdAdesioneModal .title {
  color: var(--black-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 48px;
  text-align: center ;
}

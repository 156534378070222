.SmallDivision {
  font-size: 16px;
  color: #ffffff;
  border-radius: 8px;
  background: var(--action-info-color);
  height: 48px;
  padding: 12px 16px;
  display: flex;
}

.SmallDivision > *:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
}

.SmallDivision > *:nth-child(2) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

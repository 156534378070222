.InstallersCard > *:first-child {
  border-radius: 8px;
}

.InstallersCard .InstallersInfoWindowTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.InstallersCard .InstallersInfoWindowDescription {
  font-size: 10px;
}

.InstallersList .InstallersListAreaTitle {
  color: var(--black-color);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.InstallersList .InstallersListItemTitle {
  color: var(--black-color);
  font-weight: bold;
  margin-bottom: 10px;
}

.InstallersList .InstallersListItemDescription {
  color: var(--black-color);
  margin-bottom: 20px;
}
.MyPageButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--black-color);
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  cursor: pointer;
  width: 100%;
  border: none;
}

.MyPageButton.disabled {
  cursor: auto;
}

.MyPageButton .MyPageButtonInner {
  display: flex;
  align-items: center;
  white-space: normal;
}

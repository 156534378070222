.ant-modal.WhereIsIdAdesioneModal {
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
}

.ant-modal.WhereIsIdAdesioneModal.AirModal .top {
  display: none;
}

.ant-modal.WhereIsIdAdesioneModal .content {
  font-size: 10px;
  padding: 10px;
}

.WhereIsIdAdesioneModal .Subtitle {
  color: var(--bg-color-2);
  font-size: 14px;
  margin-bottom: 15px;
}

.WhereIsIdAdesioneModal .WhereIsIdAdesioneImagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 840px) {
  .WhereIsIdAdesioneModal .WhereIsIdAdesioneImage {
    max-width: 35vw;
  }
}

@media (max-width: 840px) {
  .WhereIsIdAdesioneModal .WhereIsIdAdesioneImage {
    max-width: 80vw;
  }
}


@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif;
  --black-color: #191d3a; /* 25, 29, 58 */
  --grey-black-20-color: rgba(25, 29, 58, 0.2);
  --action-primary-color: #56d3cb;
  --action-gray-color: #acbcbb;
  --light-gray-color: #e0e2e9;
  --light-primary-color: #dfedff;
  --action-success-color: #00f7bf;
  --light-success-color: #d2f4ed;
  --action-danger-color: #ff5d58;
  --light-danger-color: #fbe1e1;
  --action-info-color: #a175fc;
  --light-info-color: #d6f4f7;
  --action-warning-color: #ffb307;
  --light-warning-color: #f8f5d5;
  --action-custom-color: #3195fe;
  --light-custom-color: #e7e7f9;
  --grey-semi-color: #e0e2e9;
  --grey-light-color: #eeeff1;
  --grey-light-color-50: rgba(238,239,241,0.5);
  --grey-grey-color: #c5c4cf;
  --grey-dark-color: #9393a0;
  --magic1-color: #f5f9fb;
  --action-brand-color: #2bb5ae;
  --light-brand-color: #d2f4ed;

  --secondary-color: #2fa0a0; /*rgb 86, 219, 209*/
  --tertiary-color: #f7fcfc; /*rgb 232, 255, 255*/

  --red-dtc-color: #ff5d58;
  --orange-dtc-color: #ffb307;
  --battery-warning-color: #ffb307;
  --alarm-color: #a175fc;
  --maintenance-color: #3195fe;

  --yellow: #ffdd07;
  --green: #22c437;

  --grey: #c9c9c9;
  --grey-2: #ceced4;
  --light-grey: #dcdcdc;
  --light-grey-2: #e6e6e6;
  --light-grey-3: #efeff1;
  --light-grey-4: #f0f0f0;
  --dark-grey: #6f7070;
  --dark-grey-2: #747575;
  --heliotrope: #A175FC;

  --bg-color: var(--light-grey-3);
  --bg-color-2: #202020;
  --bg-color-2-a05: rgba(32, 32, 32, 0.5);

  --engine-on: #2fa0a0;
  --engine-off: #819e9e;
  --lock-on: #53b3e3;
  --lock-off: #819e9e;

  --header-height: 60px;

  --selected-color: #49c6be;
  --disabled-color: #6f7070;
  --selected-tab-color: #56d3cb;

  --subheader-height: 100px;
  --health-filter-height: 80px;

  --header-with-subheader-height: calc(var(--header-height) + var(--subheader-height));

  --button-border-radius: 18px;
  --diagnostics-filters-offset: -80px;


  --red-free-service-color: #FB7670;


  --permanent-left-width: 264px;
}

.MoVeInMobileHeader {
  padding-bottom: 16px;
  border-bottom: 4px solid var(--grey-semi-color);
  margin: 16px 0 40px 0;

  display: flex;
  justify-content: space-between;
}

.MoVeInMobileHeader > * {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.RegionLogosContainer {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.MoVeInMobileFooter {
  margin: 40px 0 16px 0;
  font-size: 10px;
  text-align: center;
}

.MyPageButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--black-color);
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  cursor: pointer;
  width: 100%;
  border: none;
}

.MyPageButton.disabled {
  cursor: auto;
}

.MyPageButton .MyPageButtonInner {
  display: flex;
  align-items: center;
  white-space: normal;
}

.MyPageAlert {
}
.LabelInput {
  color: var(--black-color);
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.LabelInput .ant-input {
  background-color: white;
  border-radius: 8px;
  color: var(--black-color);
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 24px;
  padding: 4px 16px;
}

.LabelInput .ant-input-disabled {
  color: var(--grey-grey-color);
}

.LabelInput.has-error .ant-input {
  border-color: var(--action-danger-color);
}

.LabelInput > *:first-child:not(:last-child) {
  margin-bottom: 8px;
}

.LabelInputExplain {
  margin-top: 2px;
  color: var(--action-danger-color);
}

.SupportTile {
  height: 48px;
  margin-bottom: 16px;
  display: flex;
}

.SupportTile > *:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SupportTile > *:nth-child(2) {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SupportTile > *:nth-child(2) > *:first-child{
  font-weight: bold;
}

.GoogleMaps {
}
.InstallersCard > *:first-child {
  border-radius: 8px;
}

.InstallersCard .InstallersInfoWindowTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.InstallersCard .InstallersInfoWindowDescription {
  font-size: 10px;
}

.InstallersList .InstallersListAreaTitle {
  color: var(--black-color);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.InstallersList .InstallersListItemTitle {
  color: var(--black-color);
  font-weight: bold;
  margin-bottom: 10px;
}

.InstallersList .InstallersListItemDescription {
  color: var(--black-color);
  margin-bottom: 20px;
}
.MobileWelcome {
  display: flex;
  flex-direction: column;
}

.MobileWelcome .WelcomeView {
  flex: 1 0;
  margin: 0;
}

.MobileWelcome .SupportTileGroup {
  display: flex;
}

.MobileWelcome .SupportTileGroup .SupportTile {
  flex: 1 0;
}

.MobileWelcome .SupportTileGroup .SupportTile:first-child:not(:last-child) {
  margin-right: 8px;
}

.MobileWelcome .SupportTileGroup .SupportTile:last-child:not(:first-child) {
  margin-left: 8px;
}

.MobileWelcome .WhereIsIdAdesioneLink {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.ant-modal.WhereIsIdAdesioneModal {
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
}

.ant-modal.WhereIsIdAdesioneModal.AirModal .top {
  display: none;
}

.ant-modal.WhereIsIdAdesioneModal .content {
  font-size: 10px;
  padding: 10px;
}

.WhereIsIdAdesioneModal .Subtitle {
  color: var(--bg-color-2);
  font-size: 14px;
  margin-bottom: 15px;
}

.WhereIsIdAdesioneModal .WhereIsIdAdesioneImagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 840px) {
  .WhereIsIdAdesioneModal .WhereIsIdAdesioneImage {
    max-width: 35vw;
  }
}

@media (max-width: 840px) {
  .WhereIsIdAdesioneModal .WhereIsIdAdesioneImage {
    max-width: 80vw;
  }
}


.AirModal {
  position: relative;
}

.AirModal .ant-modal-content {
  border-radius: 4px;
  background-color: #f9f9fb;
}

.AirModal .close {
  position: absolute;
  right: 15px;
  top: 17px;
  display: flex;
  align-content: center;
  cursor: pointer;
}

.AirModal .buttons {
  display: flex;
  align-content: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 0 16px;
}

.AirModal .buttons > div {
  display: flex;
  align-content: center;
}

.AirModal .top {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--action-primary-color);
  height: 113px;
  margin-top: 10px;
}

.AirModal .top .title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.AirModal .top .subtitle {
  color: var(--bg-color-2);
  font-size: 16px;
}

.AirModal .content {
  padding: 30px 16px 50px 16px;
}

.MoVeInDesktopHeader {
    padding-bottom: 16px;
    border-bottom: 4px solid var(--grey-semi-color);
    margin: 24px 0 56px 0;

    display: flex;
    justify-content: space-between;
}

.MoVeInDesktopHeader > * {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RegionLogosContainer {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.MoVeInDesktopFooter {
  margin: 56px 0 24px 0;
  font-size: 10px;
  text-align: center;
}

.DesktopWelcome {
  display: flex;
  flex-direction: column;
}

.DesktopWelcome .WelcomeView {
  flex: 1 0;
  margin: 0;
}

.DesktopWelcome .SupportTileGroup {
  display: flex;
}

.DesktopWelcome .SupportTileGroup .SupportTile {
  flex: 1 0;
}

.DesktopWelcome .SupportTileGroup .SupportTile:not(:last-child) {
  margin-right: 8px;
}

.DesktopWelcome .SupportTileGroup .SupportTile:not(:first-child) {
  margin-left: 8px;
}

.DesktopWelcome .WhereIsIdAdesioneLink {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.RegForm {
  display: flex;
  margin-top: -30px;
  flex-direction: column;
}

@media (min-width: 840px) {
  .RegForm {
    flex-direction: row;
  }
}

.RegForm .left {
  flex-direction: column;
}

@media (min-width: 840px) {
  .RegForm .left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 2 1;
  }
}

@media (min-width: 840px) {
  .RegForm .left .LabelInput {
    margin-right: 24px;
  }
}

.RegForm .left {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.RegForm .right {
  margin-right: 40px;
  margin-left: 80px;
}

.RegForm .content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 840px) {
  .RegForm .ant-form {
    display: flex;
    flex-direction: column;
  }
}

/**/

.DesktopReg .RegFormUserDataForm {
  display: flex;
  flex-wrap: wrap;
}

.DesktopReg .RegFormUserDataForm > * {
  flex: 1 0;
  display: flex;
  margin-right: 24px;
  margin-bottom: 12px;
}

.DesktopReg .RegFormUserDataForm > *:last-child {
  margin-right: 0;
}

.DesktopReg .RegFormUserDataForm > * > * {
  flex-grow: 1;
}

.MobileReg .RegFormUserDataForm {
  display: flex;
  flex-direction: column;
}

.MobileReg .RegFormUserDataForm > * {
  display: flex;
  margin-right: 0;
}

.MobileReg .RegFormUserDataForm > * > * {
  flex-grow: 1;
  margin-bottom: 20px;
}

.DesktopReg .ant-form-item.SubmitContainer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.freeService600,
.freeService400 {
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.freeService600 {
  font-weight: 600;
}

.freeService400 {
  font-weight: 400;
}

.freeServiceRedColor {
  color: var(--red-free-service-color);
}

.freeServiceGreenColor {
  color: var(--action-brand-color);
}

.ButtonsContainer {
  display: flex;
  justify-content: space-evenly;
}

.MobileReg .ButtonsContainer {
  flex-direction: column;
}

.DesktopReg .ButtonsContainer {
  flex-direction: row;
}

.ant-modal.PrivacyPolicyModal {
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
}

.ant-modal.PrivacyPolicyModal.AirModal .ant-modal-content {
  /*width: 720px;*/
}

.ant-modal.PrivacyPolicyModal.AirModal .top {
  display: none;
}

.ant-modal.PrivacyPolicyModal .title {
  height: 32px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.ant-modal.PrivacyPolicyModal .content {
  font-size: 10px;
  padding: 10px;
}

.RegPrivacyPolicy {
}

.RegPrivacyPolicy .privacyBox, .RegPrivacyPolicy .privacyBox .ant-radio-wrapper{
    font-size: 14px;
}

.RegPrivacyPolicy .privacyBox>div {
     line-height: 1.5;
     margin-bottom: 10px;
     font-family: 'Work Sans', sans-serif;
     line-height: 18px;
     font-weight: 400;
     font-size: 14px;
 }

.RegPrivacyPolicy .privacyBox .informativa {
    color: var(--action-info-color);
}

.OptionalServicesView {
  line-height: 18px;
  margin-top: 20px;
}

.desktop .OptionalServicesView .SectionContent {
  padding: 30px;
  position: relative;
}

.OptionalServicesView .ServiceDetailContainer small {
  color: var(--grey-dark-color);
}

.OptionalService h2 {
  color: var(--grey-dark-color);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
}

.OptionalService h3 {
  color: var(--grey-dark-color);
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.OptionalServicesView .ant-radio-wrapper {
  margin-right: 0;
  font-size: 14px;
}

.OptionalServiceInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  background-color: #12548a;
  color: white;
}

.small .OptionalServiceInfo,
.extrasmall .OptionalServiceInfo {
  flex-wrap: wrap;
  justify-content: center;
}

.small .OptionalServiceInfo > *,
.extrasmall .OptionalServiceInfo > * {
  margin-top: 32px;
}

.small .InfoColumn,
.extrasmall .InfoColumn {
  width: 100%;
}

.desktop .OptionalServiceInfo {
  min-height: 450px;
  font-size: 16px;
}

.OptionalServicesView .ListItemContainer {
  display: flex;
}

.OptionalServicesView .ListIcon {
  color: #f6cf02;
  margin-top: 2px;
  margin-right: 13px;
}

.OptionalServiceInfo h4 {
  font-size: 1.2em;
  text-transform: uppercase;
  color: #f6cf02;
}

.OptionalServiceInfo .InfoColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.OptionalServiceInfo .AxaLogoContainer {
  margin-top: 24px;
  font-size: 12px;
  text-align: right;
}

.OptionalServiceInfo .AxaLogoContainer img {
  width: 40px;
  height: auto;
  vertical-align: baseline;
}

.OptionalServicesView .ServiceInfoIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.small .OptionalServicesView .ServiceInfoIcon,
.extrasmall .OptionalServicesView .ServiceInfoIcon {
  order: -1;
}

.OptionalServicesView .ServiceInfoIcon img {
  max-width: 200px;
  height: auto;
}

.OptionalServiceSelectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 18px;
}

.OptionalServiceSelection {
  display: flex;
}

.OptionalServiceSelection p {
  margin-right: 16px;
}

.OptionalServiceSelection .ant-checkbox-wrapper {
  margin-left: 12px;
  font-size: 18px;
}

.OptionalServicesView .ant-checkbox-inner {
  border-radius: 6px;
}

.OptionalServicePrivacy {
  font-size: 12px;
}

.LineSeparator {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-dark-color);
  margin-bottom: 20px;
}

.OptionalServicePrivacy p {
  margin: 0;
}

.OptionalServicePrivacy h4,
h4.PrivacyHeadTitle {
  font-size: 14px;
}

.OptionalServicePrivacy p{
  font-size: 14px;
}

.OptionalServicePrivacyCheck {
  margin-right: 8px;
}

.OptionalServicesView .CheckboxContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
}

.ant-modal.EmbeddedDocumentModal {
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
}

.ant-modal.EmbeddedDocumentModal.AirModal .top {
  display: none;
}

.ant-modal.EmbeddedDocumentModal .title {
  height: 32px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.ant-modal.EmbeddedDocumentModal .content {
  font-size: 10px;
  padding: 10px;
}

.AdditionalServiceProductPlanSelection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.AdditionalServiceSelectButton {
  max-width: 335px;
  min-width: 335px;
  min-height: 55px;
  height: 55px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.AdditionalServiceSelectButtonContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.AdditionalServiceDontSelectButtonContainer {
	display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
}

.AdditionalServiceDontSelectButton {
  max-width: 201px;
  min-height: 55px;
  height: 55px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.ColoredPriceAdditionalService {
  color: var(--action-brand-color);
  font-weight: 700;
}

.AdditionalServiceCheckboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.AdditionalServiceProceedButtonContainer {
  position: relative;
  min-height: 55px;
  margin-top: 25px;
}

.AdditionalServiceProceedButtonsMobileContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 25px;
}

.AdditionalServiceDontSelectMobileButton {
  min-height: 55px;
  height: 55px;
  max-width: 201px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  white-space: normal;
}

.OptionalServicesView .GeneralConditionsContainer {
  display: flex;
  flex-direction: column;
}

.GeneralConditionsContainer .GeneralConditionRow{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SmallDivision {
  font-size: 16px;
  color: #ffffff;
  border-radius: 8px;
  background: var(--action-info-color);
  height: 48px;
  padding: 12px 16px;
  display: flex;
}

.SmallDivision > *:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
}

.SmallDivision > *:nth-child(2) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.RegPerson {
  display: flex;
  flex-direction: column;
}

.DesktopReg .RegPerson .personalContent {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;

  --this-masonry-height: 90px;
  --this-masonry-item-margin-right: 24px;
  --this-masonry-item-width: calc((100% / 3) - var(--this-masonry-item-margin-right));
  height: var(--this-masonry-height);
}

.DesktopReg .RegPerson .personalContent > * {
  width: var(--this-masonry-item-width);
  margin-bottom: 20px;
}

.DesktopReg .RegPerson .personalContent > *:nth-of-type(3n + 1) {
  order: 1;
}

.DesktopReg .RegPerson .personalContent > *:nth-of-type(3n + 2) {
  order: 2;
}

.DesktopReg .RegPerson .personalContent > *:nth-of-type(3n) {
  order: 3;
  margin-right: 0;
}

.DesktopReg .RegPerson .personalContent .masonryGhostSeparator {
  flex-basis: 100%;
  width: 0;
  margin: 0;
  content: '';
  padding: 0;
}

.MobileReg .RegPerson .personalContent > * {
  margin-bottom: 20px;
}
.RegLegalPerson {
  display: flex;
  flex-direction: column;
}

.DesktopReg .RegLegalPerson .personalContent {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;

  --this-masonry-height: 270px;
  --this-masonry-item-margin-right: 24px;
  --this-masonry-item-width: calc((100% / 4) - var(--this-masonry-item-margin-right));
  height: var(--this-masonry-height);
}

.DesktopReg .RegLegalPerson .personalContent > * {
  width: var(--this-masonry-item-width);
  margin-bottom: 20px;
}

.DesktopReg .RegLegalPerson .personalContent > *:nth-of-type(4n + 1) {
  order: 1;
}

.DesktopReg .RegLegalPerson .personalContent > *:nth-of-type(4n + 2) {
  order: 2;
}

.DesktopReg .RegLegalPerson .personalContent > *:nth-of-type(4n + 3) {
  order: 3;
}

.DesktopReg .RegLegalPerson .personalContent > *:nth-of-type(4n) {
  order: 4;
  margin-right: 0;
}

.DesktopReg .RegLegalPerson .personalContent .masonryGhostSeparator {
  flex-basis: 100%;
  width: 0;
  margin: 0;
  content: '';
  padding: 0;
}

.MobileReg .RegLegalPerson .personalContent > * {
  margin-bottom: 20px;
}
.RegVehicle {
  display: flex;
  flex-direction: column;
}


.DesktopReg .RegVehicle .vehicleData {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;

  --this-masonry-height: 180px;
  --this-masonry-item-margin-right: 24px;
  --this-masonry-item-width: calc((100% / 4) - var(--this-masonry-item-margin-right));
  height: var(--this-masonry-height);
}

.DesktopReg .RegVehicle .vehicleData > * {
  width: var(--this-masonry-item-width);
  margin-bottom: 20px;
}

.DesktopReg .RegVehicle .vehicleData > *:nth-of-type(4n + 1) {
  order: 1;
}

.DesktopReg .RegVehicle .vehicleData > *:nth-of-type(4n + 2) {
  order: 2;
}

.DesktopReg .RegVehicle .vehicleData > *:nth-of-type(4n + 3) {
  order: 3;
}

.DesktopReg .RegVehicle .vehicleData > *:nth-of-type(4n) {
  order: 4;
  margin-right: 0;
}

.DesktopReg .RegVehicle .vehicleData .masonryGhostSeparator {
  flex-basis: 100%;
  width: 0;
  margin: 0;
  content: '';
  padding: 0;
}

.MobileReg .RegVehicle .vehicleData > * {
  margin-bottom: 20px;
}
.MobileReg {
}
/*

@media (max-width: 840px) {
  .MobileReg .ant-form {
    display: flex;
    flex-direction: column;
  }

  .MobileReg .fields .LabelInput {
    margin-bottom: 40px;
  }
}
*/

.DesktopReg {
  margin-left: auto;
  margin-right: auto;
}
/*
  @media (max-width: 1272px) {
    .DesktopReg {
    margin-bottom: 56px;
  }
  }
  */


.MyPageReg {}

.MyPageReg .ant-modal-confirm .ant-modal-body {
    background: white;
}

.SuccessModalContainer {
  padding: 0 15px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-modal.MyPageRegModal .content {
    font-size: 14px;
  }

  .ant-modal.MyPageRegModal.AirModal .ant-modal-content {
    /*width: 720px;*/
  }

  .ant-modal.MyPageRegModal.AirModal .top {
    display: none;
  }

  .ant-modal.MyPageRegModal .title {
    height: 32px;
    color: var(--black-color);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
  }

.MoVeInExistingVehicleNewIdAdesioneModal .content {
  font-size: 14px;
}

.MoVeInExistingVehicleNewIdAdesioneModal .top {
  display: none;
}

.MoVeInExistingVehicleNewIdAdesioneModal .title {
  color: var(--black-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 48px;
  text-align: center ;
}

.MoVeIn {
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
}

.MoVeIn > * {
  flex-grow: 1;
}

.MoVeIn.desktop {
  max-width: 1224px;
  margin: 0 auto;
}

@media (max-width: 1272px) {
  .MoVeIn.desktop {
    margin: 0 24px;
  }
}

.MoVeIn.small {
  margin: 0 16px;
}

.MoVeIn.extrasmall {
  margin: 0 16px;
}


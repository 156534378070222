.AirModal {
  position: relative;
}

.AirModal .ant-modal-content {
  border-radius: 4px;
  background-color: #f9f9fb;
}

.AirModal .close {
  position: absolute;
  right: 15px;
  top: 17px;
  display: flex;
  align-content: center;
  cursor: pointer;
}

.AirModal .buttons {
  display: flex;
  align-content: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 0 16px;
}

.AirModal .buttons > div {
  display: flex;
  align-content: center;
}

.AirModal .top {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--action-primary-color);
  height: 113px;
  margin-top: 10px;
}

.AirModal .top .title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.AirModal .top .subtitle {
  color: var(--bg-color-2);
  font-size: 16px;
}

.AirModal .content {
  padding: 30px 16px 50px 16px;
}

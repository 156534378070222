.LabelInput {
  color: var(--black-color);
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.LabelInput .ant-input {
  background-color: white;
  border-radius: 8px;
  color: var(--black-color);
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 24px;
  padding: 4px 16px;
}

.LabelInput .ant-input-disabled {
  color: var(--grey-grey-color);
}

.LabelInput.has-error .ant-input {
  border-color: var(--action-danger-color);
}

.LabelInput > *:first-child:not(:last-child) {
  margin-bottom: 8px;
}

.LabelInputExplain {
  margin-top: 2px;
  color: var(--action-danger-color);
}

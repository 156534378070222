.RegPrivacyPolicy {
}

.RegPrivacyPolicy .privacyBox, .RegPrivacyPolicy .privacyBox .ant-radio-wrapper{
    font-size: 14px;
}

.RegPrivacyPolicy .privacyBox>div {
     line-height: 1.5;
     margin-bottom: 10px;
     font-family: 'Work Sans', sans-serif;
     line-height: 18px;
     font-weight: 400;
     font-size: 14px;
 }

.RegPrivacyPolicy .privacyBox .informativa {
    color: var(--action-info-color);
}

.MoVeInDesktopHeader {
    padding-bottom: 16px;
    border-bottom: 4px solid var(--grey-semi-color);
    margin: 24px 0 56px 0;

    display: flex;
    justify-content: space-between;
}

.MoVeInDesktopHeader > * {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RegionLogosContainer {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

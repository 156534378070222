.SupportTile {
  height: 48px;
  margin-bottom: 16px;
  display: flex;
}

.SupportTile > *:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SupportTile > *:nth-child(2) {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SupportTile > *:nth-child(2) > *:first-child{
  font-weight: bold;
}

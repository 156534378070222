.MobileWelcome {
  display: flex;
  flex-direction: column;
}

.MobileWelcome .WelcomeView {
  flex: 1 0 0;
  margin: 0;
}

.MobileWelcome .SupportTileGroup {
  display: flex;
}

.MobileWelcome .SupportTileGroup .SupportTile {
  flex: 1 0 0;
}

.MobileWelcome .SupportTileGroup .SupportTile:first-child:not(:last-child) {
  margin-right: 8px;
}

.MobileWelcome .SupportTileGroup .SupportTile:last-child:not(:first-child) {
  margin-left: 8px;
}

.MobileWelcome .WhereIsIdAdesioneLink {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
}

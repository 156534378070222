.MoVeIn {
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
}

.MoVeIn > * {
  flex-grow: 1;
}

.MoVeIn.desktop {
  max-width: 1224px;
  margin: 0 auto;
}

@media (max-width: 1272px) {
  .MoVeIn.desktop {
    margin: 0 24px;
  }
}

.MoVeIn.small {
  margin: 0 16px;
}

.MoVeIn.extrasmall {
  margin: 0 16px;
}

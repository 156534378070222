.OptionalServicesView {
  line-height: 18px;
  margin-top: 20px;
}

.desktop .OptionalServicesView .SectionContent {
  padding: 30px;
  position: relative;
}

.OptionalServicesView .ServiceDetailContainer small {
  color: var(--grey-dark-color);
}

.OptionalService h2 {
  color: var(--grey-dark-color);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
}

.OptionalService h3 {
  color: var(--grey-dark-color);
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.OptionalServicesView .ant-radio-wrapper {
  margin-right: 0;
  font-size: 14px;
}

.OptionalServiceInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  background-color: #12548a;
  color: white;
}

.small .OptionalServiceInfo,
.extrasmall .OptionalServiceInfo {
  flex-wrap: wrap;
  justify-content: center;
}

.small .OptionalServiceInfo > *,
.extrasmall .OptionalServiceInfo > * {
  margin-top: 32px;
}

.small .InfoColumn,
.extrasmall .InfoColumn {
  width: 100%;
}

.desktop .OptionalServiceInfo {
  min-height: 450px;
  font-size: 16px;
}

.OptionalServicesView .ListItemContainer {
  display: flex;
}

.OptionalServicesView .ListIcon {
  color: #f6cf02;
  margin-top: 2px;
  margin-right: 13px;
}

.OptionalServiceInfo h4 {
  font-size: 1.2em;
  text-transform: uppercase;
  color: #f6cf02;
}

.OptionalServiceInfo .InfoColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.OptionalServiceInfo .AxaLogoContainer {
  margin-top: 24px;
  font-size: 12px;
  text-align: right;
}

.OptionalServiceInfo .AxaLogoContainer img {
  width: 40px;
  height: auto;
  vertical-align: baseline;
}

.OptionalServicesView .ServiceInfoIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.small .OptionalServicesView .ServiceInfoIcon,
.extrasmall .OptionalServicesView .ServiceInfoIcon {
  order: -1;
}

.OptionalServicesView .ServiceInfoIcon img {
  max-width: 200px;
  height: auto;
}

.OptionalServiceSelectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 18px;
}

.OptionalServiceSelection {
  display: flex;
}

.OptionalServiceSelection p {
  margin-right: 16px;
}

.OptionalServiceSelection .ant-checkbox-wrapper {
  margin-left: 12px;
  font-size: 18px;
}

.OptionalServicesView .ant-checkbox-inner {
  border-radius: 6px;
}

.OptionalServicePrivacy {
  font-size: 12px;
}

.LineSeparator {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-dark-color);
  margin-bottom: 20px;
}

.OptionalServicePrivacy p {
  margin: 0;
}

.OptionalServicePrivacy h4,
h4.PrivacyHeadTitle {
  font-size: 14px;
}

.OptionalServicePrivacy p{
  font-size: 14px;
}

.OptionalServicePrivacyCheck {
  margin-right: 8px;
}

.OptionalServicesView .CheckboxContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
}

.ant-modal.EmbeddedDocumentModal {
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
}

.ant-modal.EmbeddedDocumentModal.AirModal .top {
  display: none;
}

.ant-modal.EmbeddedDocumentModal .title {
  height: 32px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.ant-modal.EmbeddedDocumentModal .content {
  font-size: 10px;
  padding: 10px;
}

.AdditionalServiceProductPlanSelection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.AdditionalServiceSelectButton {
  max-width: 335px;
  min-width: 335px;
  min-height: 55px;
  height: 55px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.AdditionalServiceSelectButtonContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.AdditionalServiceDontSelectButtonContainer {
	display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
}

.AdditionalServiceDontSelectButton {
  max-width: 201px;
  min-height: 55px;
  height: 55px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.ColoredPriceAdditionalService {
  color: var(--action-brand-color);
  font-weight: 700;
}

.AdditionalServiceCheckboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.AdditionalServiceProceedButtonContainer {
  position: relative;
  min-height: 55px;
  margin-top: 25px;
}

.AdditionalServiceProceedButtonsMobileContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}

.AdditionalServiceDontSelectMobileButton {
  min-height: 55px;
  height: 55px;
  max-width: 201px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  white-space: normal;
}

.OptionalServicesView .GeneralConditionsContainer {
  display: flex;
  flex-direction: column;
}

.GeneralConditionsContainer .GeneralConditionRow{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.RegVehicle {
  display: flex;
  flex-direction: column;
}


.DesktopReg .RegVehicle .vehicleData {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;

  --this-masonry-height: 180px;
  --this-masonry-item-margin-right: 24px;
  --this-masonry-item-width: calc((100% / 4) - var(--this-masonry-item-margin-right));
  height: var(--this-masonry-height);
}

.DesktopReg .RegVehicle .vehicleData > * {
  width: var(--this-masonry-item-width);
  margin-bottom: 20px;
}

.DesktopReg .RegVehicle .vehicleData > *:nth-of-type(4n + 1) {
  order: 1;
}

.DesktopReg .RegVehicle .vehicleData > *:nth-of-type(4n + 2) {
  order: 2;
}

.DesktopReg .RegVehicle .vehicleData > *:nth-of-type(4n + 3) {
  order: 3;
}

.DesktopReg .RegVehicle .vehicleData > *:nth-of-type(4n) {
  order: 4;
  margin-right: 0;
}

.DesktopReg .RegVehicle .vehicleData .masonryGhostSeparator {
  flex-basis: 100%;
  width: 0;
  margin: 0;
  content: '';
  padding: 0;
}

.MobileReg .RegVehicle .vehicleData > * {
  margin-bottom: 20px;
}
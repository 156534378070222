.MoVeInMobileHeader {
  padding-bottom: 16px;
  border-bottom: 4px solid var(--grey-semi-color);
  margin: 16px 0 40px 0;

  display: flex;
  justify-content: space-between;
}

.MoVeInMobileHeader > * {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.RegionLogosContainer {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.RegForm {
  display: flex;
  margin-top: -30px;
  flex-direction: column;
}

@media (min-width: 840px) {
  .RegForm {
    flex-direction: row;
  }
}

.RegForm .left {
  flex-direction: column;
}

@media (min-width: 840px) {
  .RegForm .left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 2;
  }
}

@media (min-width: 840px) {
  .RegForm .left .LabelInput {
    margin-right: 24px;
  }
}

.RegForm .left {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.RegForm .right {
  margin-right: 40px;
  margin-left: 80px;
}

.RegForm .content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 840px) {
  .RegForm .ant-form {
    display: flex;
    flex-direction: column;
  }
}

/**/

.DesktopReg .RegFormUserDataForm {
  display: flex;
  flex-wrap: wrap;
}

.DesktopReg .RegFormUserDataForm > * {
  flex: 1 0 0;
  display: flex;
  margin-right: 24px;
  margin-bottom: 12px;
}

.DesktopReg .RegFormUserDataForm > *:last-child {
  margin-right: 0;
}

.DesktopReg .RegFormUserDataForm > * > * {
  flex-grow: 1;
}

.MobileReg .RegFormUserDataForm {
  display: flex;
  flex-direction: column;
}

.MobileReg .RegFormUserDataForm > * {
  display: flex;
  margin-right: 0;
}

.MobileReg .RegFormUserDataForm > * > * {
  flex-grow: 1;
  margin-bottom: 20px;
}

.DesktopReg .ant-form-item.SubmitContainer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.freeService600,
.freeService400 {
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.freeService600 {
  font-weight: 600;
}

.freeService400 {
  font-weight: 400;
}

.freeServiceRedColor {
  color: var(--red-free-service-color);
}

.freeServiceGreenColor {
  color: var(--action-brand-color);
}

.ButtonsContainer {
  display: flex;
  justify-content: space-evenly;
}

.MobileReg .ButtonsContainer {
  flex-direction: column;
}

.DesktopReg .ButtonsContainer {
  flex-direction: row;
}

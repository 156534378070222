.RegLegalPerson {
  display: flex;
  flex-direction: column;
}

.DesktopReg .RegLegalPerson .personalContent {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;

  --this-masonry-height: 270px;
  --this-masonry-item-margin-right: 24px;
  --this-masonry-item-width: calc((100% / 4) - var(--this-masonry-item-margin-right));
  height: var(--this-masonry-height);
}

.DesktopReg .RegLegalPerson .personalContent > * {
  width: var(--this-masonry-item-width);
  margin-bottom: 20px;
}

.DesktopReg .RegLegalPerson .personalContent > *:nth-of-type(4n + 1) {
  order: 1;
}

.DesktopReg .RegLegalPerson .personalContent > *:nth-of-type(4n + 2) {
  order: 2;
}

.DesktopReg .RegLegalPerson .personalContent > *:nth-of-type(4n + 3) {
  order: 3;
}

.DesktopReg .RegLegalPerson .personalContent > *:nth-of-type(4n) {
  order: 4;
  margin-right: 0;
}

.DesktopReg .RegLegalPerson .personalContent .masonryGhostSeparator {
  flex-basis: 100%;
  width: 0;
  margin: 0;
  content: '';
  padding: 0;
}

.MobileReg .RegLegalPerson .personalContent > * {
  margin-bottom: 20px;
}
.DesktopWelcome {
  display: flex;
  flex-direction: column;
}

.DesktopWelcome .WelcomeView {
  flex: 1 0 0;
  margin: 0;
}

.DesktopWelcome .SupportTileGroup {
  display: flex;
}

.DesktopWelcome .SupportTileGroup .SupportTile {
  flex: 1 0 0;
}

.DesktopWelcome .SupportTileGroup .SupportTile:not(:last-child) {
  margin-right: 8px;
}

.DesktopWelcome .SupportTileGroup .SupportTile:not(:first-child) {
  margin-left: 8px;
}

.DesktopWelcome .WhereIsIdAdesioneLink {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
}
